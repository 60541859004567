<template>
  <div class="about">
    <div class="titles azjgs">
      <input-list @setData="getData" iptType="1"></input-list>
      <div>
        手机号：
        <el-input type="number" v-model="cphone" placeholder="请输入联系电话" clearable></el-input>
      </div>
      <div>
        姓名：
        <el-input v-model="cname" placeholder="请输入姓名" clearable></el-input>
      </div>
      <div>
        性别：
        <el-select v-model="cgender" placeholder="全部" clearable>
          <el-option :value="0" label="女"></el-option>
          <el-option :value="1" label="男"></el-option>
        </el-select>
      </div>
      <span>
        <el-button v-if="$has('viewMgrDevUser')" type="success" size="mini" icon="el-icon-search" @click="searchs(1, pagesize)">查 询</el-button>
        <el-button type="info" size="mini" icon="el-icon-refresh-right" @click="reserts">重 置</el-button>
        <el-button v-if="$has('addMgrDevUser')" type="warning" size="mini" icon="el-icon-plus" @click="openDialog(0)">新 增</el-button>
      </span>
    </div>
    <div class="cont">
      <el-table :data="tableData" height="calc(100% - 40px)">
        <el-table-column prop="areaName" label="区域名称"></el-table-column>
        <el-table-column prop="organizationName" label="机构名称"></el-table-column>
        <el-table-column prop="name" label="姓名"></el-table-column>
        <el-table-column prop="gender" label="性别">
          <template slot-scope="{ row }">
            <div>{{ row.gender == 0 ? '女' : '男' }}</div>
          </template>
        </el-table-column>
        <el-table-column prop="phone" label="手机号"></el-table-column>
        <el-table-column prop="useFlag" label=" 状态">
          <template slot-scope="{ row }">
            <div>{{ row.useFlag == 0 ? '启用' : '禁用' }}</div>
          </template>
        </el-table-column>
        <el-table-column prop="createDt" label="创建时间"></el-table-column>
        <el-table-column prop="updateDt" label="更新时间"></el-table-column>
        <el-table-column label="操作" width="190">
          <template slot-scope="{ row }">
            <el-button v-if="$has('editMgrDevUser')" @click="openDialog(1, row)" type="primary" size="small" icon="el-icon-edit-outline">编 辑</el-button>
            <el-button v-if="$has('delMgrDevUser')" type="danger" size="small" @click="deletes(row.id)" icon="el-icon-close">删 除</el-button>
          </template>
        </el-table-column>
        <div slot="empty" class="empty">
          <img src="../../public/home/noData.svg" width="320" alt="" />
          <span>暂无数据</span>
        </div>
      </el-table>
      <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currpage" :page-sizes="[10, 20, 100, 500, 1000, 5000, 10000]" :page-size="pagesize" layout="total, sizes, prev, pager, next, jumper" :total="totals"> </el-pagination>
      <ToTop />
      <!-- 新增,编辑模态框 -->
      <el-dialog :title="dialogTitle" :visible.sync="dialogVisible" width="30%" :modal="true" :close-on-click-modal="false" @close="cancles" v-dialogDrag center>
        <div class="dialogsC">
          <div class="newDialog">
            <input-list v-if="!isUseFlag" @setData="getData" iptType="2" :isRequired="true"></input-list>
            <div v-if="isUseFlag">
              <i class="ired">* </i>
              <span>区域名称:</span>
              <el-input v-model="lgxqy" clearable :disabled="isUseFlag"></el-input>
            </div>
            <div v-if="isUseFlag">
              <i class="ired">* </i>
              <span>采样场所:</span>
              <el-input v-model="jcdName" clearable :disabled="isUseFlag"></el-input>
            </div>
            <div>
              <i class="ired">* </i>
              <span>姓名：</span>
              <el-input placeholder="姓名" v-model="paramsObj.name" clearable></el-input>
            </div>
            <div>
              <i class="ired">* </i>
              <span>性别：</span>
              <el-select placeholder="全部" v-model="paramsObj.gender" clearable>
                <el-option :value="0" label="女"></el-option>
                <el-option :value="1" label="男"></el-option>
              </el-select>
            </div>
            <div>
              <i class="ired">* </i>
              <span>手机号：</span>
              <el-input placeholder="手机号" v-model="paramsObj.phone" clearable></el-input>
            </div>
            <div v-if="isUseFlag">
              <i class="ired">* </i>
              <span>状态:</span>
              <el-select v-model="paramsObj.useFlag" clearable>
                <el-option :value="0" label="启用"></el-option>
                <el-option :value="1" label="禁用"></el-option>
              </el-select>
            </div>
          </div>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="cancles">取 消</el-button>
          <el-button type="primary" @click="totalConfirm">确 定</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import qs from 'qs'
import { deviceuserQueryWithPage, deviceuserSave, deviceuserUpdate, deviceuserDelete } from '@/util/http'
import inputList from '@/components/inputList/inputList.vue'
import ToTop from '@/components/toTop/toTop.vue'
export default {
  components: { inputList, ToTop },
  data() {
    return {
      currpage: 1,
      pagesize: 10,
      totals: 0,
      tableData: [], //数据
      cgxqy: '', //查询区域名称名称id
      cjgmc: '', //查询机构名称id
      cphone: '', //查询电话
      cgender: '', //查询性别
      cname: '', //查询姓名
      user: '',
      dialogTitle: '新增设备使用者', //模态框标题
      dialogVisible: false, //模态框显影
      paramsObj: {}, //模态框字段
      dialogType: '', //新增编辑字段
      isUseFlag: false, //状态
      lgxqy: '', //区域中文
      jcdName: '', //机构中文
    }
  },
  methods: {
    // 拿值区域和机构
    getData(msg, iptType) {
      if (iptType == 1) {
        this.cgxqy = msg[0]
        this.cjgmc = msg[1]
      } else {
        this.paramsObj.areaId = msg[0]
        this.paramsObj.organizationId = msg[1]
      }
    },
    // 分页
    handleSizeChange(val) {
      this.pagesize = val
      this.searchs(this.currpage, val)
    },
    handleCurrentChange(val) {
      this.currpage = val
      this.searchs(val, this.pagesize)
    },
    // 查询
    searchs(currpage, pagesize) {
      this.currpage = currpage
      let params = {
        pageNo: currpage,
        pageSize: pagesize,
        areaId: this.cgxqy,
        organizationId: this.cjgmc,
        phone: this.cphone,
        gender: this.cgender,
        name: this.cname,
      }
      deviceuserQueryWithPage(params).then((data) => {
        this.tableData = data.result.records
        this.totals = data.result.total
      })
    },
    // 重置
    reserts() {
      this.currpage = 1
      this.pagesize = 10
      this.$bus.$emit('clickResert', 1)
      this.cgxqy = this.user.areaId
      this.cjgmc = this.user.organizationId
      this.cphone = ''
      this.cgender = ''
      this.cname = ''
      this.searchs(1, 10)
    },
    // 取消
    cancles() {
      this.aname = ''
      this.aphone = ''
      this.agender = ''
      this.cgxqy = this.user.areaId
      this.cjgmc = this.user.organizationId
      this.isUseFlag = false
      this.paramsObj = {}
      this.dialogVisible = false
      this.$bus.$emit('clickResert', 2)
    },
    // 展开模态框
    openDialog(type, row) {
      this.dialogType = type
      if (type == 0) {
        //新增
        this.dialogTitle = '新增设备使用者'
        this.isUseFlag = false
        this.paramsObj.useFlag = 0
      } else {
        //编辑
        this.paramsObj = Object.assign({}, row)
        this.lgxqy = row.areaName
        this.jcdName = row.organizationName
        this.dialogTitle = '编辑设备使用者'
        this.isUseFlag = true
      }
      this.dialogVisible = true
    },
    // 新增，编辑确认
    totalConfirm() {
      if (!this.paramsObj.areaId) {
        this.$message.error('区域名称不能为空')
        return
      }
      if (!this.paramsObj.organizationId) {
        this.$message.error('机构名称不能为空')
        return
      }
      if (!this.paramsObj.name) {
        this.$message.error('姓名不能为空')
        return
      }
      if (!String(this.paramsObj.gender) || this.paramsObj.gender == undefined) {
        this.$message.error('性别不能为空')
        return
      }
      let reg = /^(0|86|17951)?(13[0-9]|15[012356789]|166|17[3678]|18[0-9]|14[57])[0-9]{8}$/
      if (!reg.test(this.paramsObj.phone) || !this.paramsObj.phone.length == 11) {
        this.$message.error('手机号输入不正确')
        return
      }
      if (!String(this.paramsObj.useFlag) && this.dialogType == 1) {
        this.$message.error('状态不能为空')
        return
      }
      // console.log(this.paramsObj);
      this.paramsObj = qs.stringify(this.paramsObj)
      if (this.dialogType == 0) {
        deviceuserSave(this.paramsObj).then((data) => {
          if (data) this.returnState(data)
        })
      } else {
        deviceuserUpdate(this.paramsObj).then((data) => {
          if (data) this.returnState(data)
        })
      }
    },
    // 删除
    deletes(id) {
      this.$confirm('是否删除该设备使用者?', '提示', {
        confirmButtonText: '删除',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          deviceuserDelete(qs.stringify({ id })).then((data) => {
            if (data) {
              let deleteAfterPage = Math.ceil((this.totals - 1) / this.pagesize)
              let currentPage = this.currpage > deleteAfterPage ? deleteAfterPage : this.currpage
              this.currpage = currentPage < 1 ? 1 : currentPage
              this.returnState(data)
            }
          })
        })
        .catch(() => {})
    },
    // 返回状态
    returnState(data) {
      this.$message.success(data.message)
      this.cancles()
      this.searchs(this.currpage, this.pagesize)
    },
  },
  mounted() {
    this.user = JSON.parse(sessionStorage.getItem('user'))
    this.cgxqy = this.user.areaId
    this.cjgmc = this.user.organizationId
    this.searchs(1, 10)
  },
}
</script>
<style src="../../public/css/search.less" lang="less" scoped></style>
<style lang="less" scoped>
.spans {
  span {
    width: 23%;
    display: inline-block;
    text-align: right;
  }
  .title {
    font-size: inherit;
    text-align: center;
    div {
      margin: 0;
    }
    /deep/.el-select {
      margin: 6px 0 !important;
      margin-right: 0;
    }
  }
  div {
    margin: 16px 0;
  }
}
</style>
